import ApplicationController from 'javascript/controllers/application_controller';

export default class extends ApplicationController {
  static targets = ['iframe', 'open', 'container', 'backdrop'];

  connect() {
    this.initEmbed();
    this.expanded = false;
  }

  open() {
    if (this.expanded) {
      return;
    }

    this.expanded = true;

    $(this.openTarget).addClass('inventive__button--disabled');
    $(this.backdropTarget).addClass('inventive__backdrop--disabled');
    $(this.openTarget).fadeOut(() => {
      $(this.containerTarget).animate({ width: 'toggle' });
    });
    $(this.backdropTarget).show();
    $(this.backdropTarget).animate(
      { opacity: 0.6 },
      {
        done: () => {
          $(this.backdropTarget).removeClass('inventive__backdrop--disabled');
        },
      },
    );

    $('body').css('overflow-y', 'hidden');
  }

  close() {
    if (!this.expanded) {
      return;
    }

    this.expanded = false;

    $(this.openTarget).addClass('inventive__button--disabled');
    $(this.backdropTarget).addClass('inventive__backdrop--disabled');
    $(this.containerTarget).animate(
      { width: 'toggle' },
      {
        done: () => {
          $(this.openTarget).fadeIn();
        },
      },
    );
    $(this.backdropTarget).animate(
      { opacity: 0 },
      {
        done: () => {
          $(this.backdropTarget).hide();
          $(this.openTarget).removeClass('inventive__button--disabled');
        },
      },
    );

    $('body').css('overflow-y', 'auto');
  }

  initEmbed() {
    fetch(this.data.get('proxy-path'))
      .then((response) => response.json())
      .then((data) => {
        if (!data.url) {
          return;
        }

        $(this.openTarget).fadeIn();

        this.iframeTarget.src = data.url;
        window.onmessage = (event) => {
          const targetOrigin = new URL(data.url).origin;

          if (event.origin !== targetOrigin) {
            // ignore messages from other origins
            return;
          }
          const { type } = event.data;
          if (type !== 'embed_content_ready') {
            // ignore messages with other types
            return;
          }

          const embedTokensMsg = {
            type: 'embed_tokens',
            tokens: data.tokens,
            scopeToken: data.scopeToken,
            hostUrl: data.hostUrl,
          };
          this.iframeTarget.contentWindow.postMessage(
            embedTokensMsg,
            targetOrigin,
          );

          window.onmessage = null;
        };
      })
      .catch((error) => console.error('Error fetching iframe URL:', error));
  }
}
